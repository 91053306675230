<template>
  <div class="index">
    <Home />
  </div>
</template>

<script>
import Vue from 'vue'; // @ is an alias to /src
import Home from '@/components/home/home.vue';

export default Vue.extend({
  name: 'Index',
  components: {
    Home,
  },
});
</script>
