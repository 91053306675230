<template>
  <div class="home">
    <Insight
      :nodeAmount="this.nodeAmount"
      :totalTxns="this.totalTxns"
      :blockHeight="this.blockHeight"
      :todayTxns="this.todayTxns"
      :todayBlocks="this.todayBlocks"
      :contractGroup="this.contractGroup"
      style="padding-bottom: 20px;"
    />
    <el-row>
      <el-col :span="12">
        <el-card style="margin-right:10px;" shadow="hover">
          <div slot="header" class="clearfix">
            <span>最近交易</span>
          </div>
          <div>
            <TxList layout="compact" />
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card style="margin-left:10px;" shadow="hover">
          <div slot="header" class="clearfix">
            <span>最近出块</span>
          </div>
          <div>
            <BlkList layout="compact" />
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Vue from 'vue';
import Insight from '@/components/home/insight.vue';
import TxList from '@/components/transaction/list.vue';
import BlkList from '@/components/block/list.vue';

export default Vue.extend({
  name: 'Home',
  props: {},
  data() {
    return {
      nodeAmount: 12,
      totalTxns: 25565,
      blockHeight: 3400,
      todayTxns: 230,
      todayBlocks: 10,
      contractGroup: 8,
    };
  },
  components: {
    Insight,
    TxList,
    BlkList,
  },
});
</script>

<style scoped>
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
</style>
