<template>
  <el-table
    :data="tableData"
    style="width: 100%"
    :show-header="false"
  >
    <el-table-column fixed prop="title" label="标题" width="150">
    </el-table-column>
    <el-table-column prop="value" label="数据" width="auto"> </el-table-column>
  </el-table>
</template>

<script>
import Vue from 'vue';
import * as dayjs from 'dayjs';

export default Vue.extend({
  props: [
    'blockNumber',
    'timestamp',
    'txns',
    'sealer',
    'difficulty',
    'totalDifficulty',
    'blockHash',
    'parentHash',
    'nonce',
  ],
  data() {
    return {
      tableData: [
        {
          title: '区块号',
          value: this.blockNumber,
        },
        {
          title: '出块时间',
          value: dayjs.unix(this.timestamp).format('YYYY/MM/DD HH:mm:ss'),
        },
        {
          title: '包含交易',
          value: this.txns.length,
        },
        {
          title: '打包地址',
          value: this.sealer,
        },
        {
          title: '难度',
          value: this.difficulty,
        },
        {
          title: '总计难度',
          value: this.totalDifficulty,
        },
        {
          title: '区块哈希',
          value: this.blockHash,
        },
        {
          title: '区块父哈希',
          value: this.parentHash,
        },
        {
          title: '随机数',
          value: this.nonce,
        },
      ],
    };
  },
});
</script>
