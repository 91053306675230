<template>
  <div>
    <el-pagination
      @current-change="handleCurrentChange"
      background
      :current-page.sync="currentPage"
      :page-size="20"
      layout="total, prev, pager, next, jumper"
      :total="this.total"
    >
    </el-pagination>
    <el-divider></el-divider>
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      empty-text="链上数据为空"
      @row-click="handleRowClick"
    >
      <el-table-column prop="name" label="合约名称"> </el-table-column>
      <el-table-column
        prop="address"
        label="合约地址"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column prop="txCount" label="交易数量"> </el-table-column>
      <el-table-column
        prop="addrCount"
        label="进行过交易的历史地址数量"
      ></el-table-column>
      <el-table-column
        prop="amount"
        label="合约组包含的合约数量"
      ></el-table-column>
    </el-table>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: ['category', 'total'],
  data() {
    return {
      currentPage: 1,
      tableData: [],
    };
  },
  methods: {
    handleCurrentChange(page) {
      this.currentPage = page;
    },
    handleRowClick(row) {
      this.$router.push({ path: `/contract/${row.address}` });
    },
  },
  mounted() {
    // TODO: fetch the table data
    switch (this.category) {
      case 'did':
        this.tableData = [
          {
            name: 'SMA-DID',
            address: '0x0D28588d9ace80CfEc2C9B06Fd26735a937f6dC7',
            txCount: 23132,
            addrCount: 30,
            amount: 3,
          },
        ];
        break;
      default:
        break;
    }
  },
});
</script>
