<template>
  <div class="block">
    <div>
      <h2>区块列表</h2>
      <span>仅显示最近1000个区块</span>
      <el-divider></el-divider>
    </div>
    <div>
      <el-card shadow="never">
        <BlkList layout="full" />
      </el-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import BlkList from '@/components/block/list.vue';

export default Vue.extend({
  name: 'Transaction',
  components: {
    BlkList,
  },
  data() {
    return {
      tableData: [
        {
          blockNumber: 8996949,
          txCount: 3,
          miner: '0x52351e33b3c693cc05f21831647ebdab8a68eb95',
          time: '2020-09-02 12:33:43',
        },
      ],
    };
  },
});
</script>

<style>
.block {
  margin-left: 30px;
  margin-right: 30px;
}
</style>
