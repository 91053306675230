import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/views/home.vue';
import About from '@/views/About.vue';
import Transaction from '@/views/transaction.vue';
import TransactionDetail from '@/views/transactionDetail.vue';
import Contract from '@/views/contract.vue';
import ContractDetail from '@/views/contractDetail.vue';
import Block from '@/views/block.vue';
import BlockDetail from '@/views/blockDetail.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/transaction',
    name: 'Transaction',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import('@/views/transaction.vue'),
    component: Transaction,
  },
  {
    path: '/transaction/:hash',
    name: 'TransactionDetail',
    component: TransactionDetail,
  },
  {
    path: '/block',
    name: 'Block',
    component: Block,
  },
  {
    path: '/block/:number',
    name: 'BlockDetail',
    component: BlockDetail,
  },
  {
    path: '/contract',
    name: 'Contract',
    component: Contract,
  },
  {
    path: '/contract/:address',
    name: 'ContractDetail',
    component: ContractDetail,
  },
  {
    path: '/help',
    name: 'Help',
    // component: () => import('@/views/index.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
