<template>
  <div>
    <div v-if="layout === 'full'">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :current-page.sync="currentPage"
        :page-size="20"
        layout="total, prev, pager, next, jumper"
        :total="this.total"
      >
      </el-pagination>
      <el-divider></el-divider>
      <el-table
        :data="tableData"
        @row-click="handleRowClick"
        stripe
        style="width: 100%"
        empty-text="链上数据为空"
      >
        <el-table-column
          prop="blockNumber"
          label="区块号"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="time"
          label="出块时间"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="txCount"
          label="交易数量"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="miner"
          label="出块"
          :show-overflow-tooltip="true"
        ></el-table-column>
      </el-table>
    </div>
    <div v-if="layout === 'compact'">
      <div v-for="data in tableData.slice(0, 7)" :key="data.txhash">
        <el-col>
          <el-row align="middle" justify="center" type="flex" @click.native="handleRowClick(data)">
            <el-col :span="8">
              <el-row class="address">{{ data.blockNumber }}...</el-row>
              <el-row class="time">{{ getTimelapse(data.time) }}</el-row>
            </el-col>
            <el-col :span="16">
              <el-row>
                <span class="label">出块地址 </span>
                <span class="link">{{ data.miner.substring(0, 12) }}...</span>
              </el-row>
              <el-row>
                <span class="label">交易数 </span>
                <span class="link">{{ data.txCount }}...</span>
              </el-row>
            </el-col>
          </el-row>
          <el-divider></el-divider>
        </el-col>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/zh-cn';

export default Vue.extend({
  props: ['layout'],
  data() {
    return {
      currentPage: 1,
      total: 1,
      tableData: [],
    };
  },
  methods: {
    handleCurrentChange(page) {
      this.currentPage = page;
    },
    handleRowClick(row) {
      this.$router.push({ path: `/block/${row.blockNumber}` });
    },
    fetchTableData() {
      // fetch table data
      this.tableData = [
        {
          blockNumber: 8996949,
          txCount: 3,
          miner: '0x52351e33b3c693cc05f21831647ebdab8a68eb95',
          time: Date.now() / 1000 - 30,
        },
        {
          blockNumber: 8996948,
          txCount: 72,
          miner: '0x52351e33b3c693cc05f21831647ebdab8a68eb95',
          time: Date.now() / 1000 - 30,
        },
        {
          blockNumber: 8996947,
          txCount: 4,
          miner: '0x05fc5a079e0583b8a07526023a16e2022c4c6296',
          time: Date.now() / 1000 - 90,
        },
        {
          blockNumber: 8996946,
          txCount: 48,
          miner: '0x52351e33b3c693cc05f21831647ebdab8a68eb95',
          time: Date.now() / 1000 - 130,
        },
        {
          blockNumber: 8996945,
          txCount: 1,
          miner: '0x05fc5a079e0583b8a07526023a16e2022c4c6296',
          time: Date.now() / 1000 - 150,
        },
      ];
      this.total = 1;
    },
    getTimelapse(time) {
      return dayjs.unix(time).fromNow();
    },
  },
  mounted() {
    this.fetchTableData();
    dayjs.extend(relativeTime);
    dayjs.locale('zh-cn');
  },
});
</script>
