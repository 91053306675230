import Vue from 'vue';
import Vuex from 'vuex';

import { Chain } from 'crypu';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    chain: Chain.ETHERS,
    network: 'ropsten',
    chainId: 3,
    node: 'https://ropsten.infura.io/v3/9d06e65c4d78469cbd0b7edb35a23cae',
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
});
