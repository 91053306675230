<template>
  <div>
    <ECharts :options="options" style="width:100%;height:100%;"></ECharts>
  </div>
</template>

<script>
import Vue from 'vue';
import * as dayjs from 'dayjs';
import ECharts from 'vue-echarts';

import 'echarts/lib/chart/line';

export default Vue.extend({
  props: ['data'],
  data() {
    return {
      $echarts: undefined,
      options: {
        grid: {
          x: 30,
          y: 15,
          x2: 15,
          y2: 20,
        },
        xAxis: {
          type: 'category',
          data: [
            dayjs().subtract(6, 'Day').date(),
            dayjs().subtract(5, 'Day').date(),
            dayjs().subtract(4, 'Day').date(),
            dayjs().subtract(3, 'Day').date(),
            dayjs().subtract(2, 'Day').date(),
            dayjs().subtract(1, 'Day').date(),
            dayjs().date(),
          ],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: this.data,
            type: 'line',
            smooth: true,
          },
        ],
      },
    };
  },
  components: {
    ECharts,
  },
});
</script>

<style>
.echart {
  height: 100%;
  width: 100%;
}
</style>
