<template>
  <div>
    <ECharts :options="options" style="width: 100vw;height: 70vh;"></ECharts>
  </div>
</template>

<script>
import Vue from 'vue';
// import * as dayjs from 'dayjs';
import ECharts from 'vue-echarts';
import 'echarts/lib/chart/graph';

export default Vue.extend({
  props: ['address'],
  data() {
    return {
      $echarts: undefined,
      data: [],
      links: [],
      options: {
        title: {
          text: this.address,
          subtext: '图形可以缩放或拖动, 灰色块为入口合约',
        },
        animationDurationUpdate: 1500,
        animationEasingUpdate: 'quinticInOut',
        tooltip: {
          trigger: 'item',
          formatter: (data) => data.value,
        },
        roam: true,
        label: {
          show: true,
        },
        series: [
          {
            type: 'graph',
            layout: 'force',
            symbol: 'roundRect',
            symbolSize: 70,
            roam: true,
            label: {
              show: true,
            },
            force: {
              repulsion: 2200,
              edgeLength: [150, 100],
            },
            edgeSymbol: ['circle', 'arrow'],
            edgeSymbolSize: [4, 20],
            edgeLabel: {
              fontSize: 20,
            },
            draggable: true,
            data: [],
            links: [],
            lineStyle: {
              opacity: 0.9,
              width: 2,
              curveness: 0,
            },
          },
        ],
      },
    };
  },
  mounted() {
    // Load relation from server
    this.data = [
      {
        name: '节点1',
        value:
          '<p>IdentityEntrance</p><p>0xE71eE3A87C2895a27459661495e8Ed5d93033350</p>',
        itemStyle: { color: 'grey' },
      },
      {
        name: '节点2',
      },
      {
        name: '节点3',
      },
      {
        name: '节点4',
      },
    ];
    this.links = [
      {
        source: '节点1',
        target: '节点3',
      },
      {
        source: '节点2',
        target: '节点3',
      },
      {
        source: '节点2',
        target: '节点4',
      },
      {
        source: '节点1',
        target: '节点4',
      },
    ];
    this.options.series[0].data = this.data;
    this.options.series[0].links = this.links;

    // TODO: Pend contract name to chart title
  },
  components: {
    ECharts,
  },
});
</script>
