<template>
  <div>
    <div v-if="validateHash">
      <Detail />
    </div>
    <div v-else>
      <Page404 msg="请输入正确的合约地址哈希值" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

import Page404 from '@/views/404.vue';
import Detail from '@/components/contract/detail.vue';

export default Vue.extend({
  components: {
    Detail,
    Page404,
  },
  computed: {
    validateHash() {
      return /^0x([A-Fa-f0-9]{40})$/.test(this.$route.params.address);
    },
  },
});
</script>
