<template>
  <div>
    <div>
      <h4>地址</h4>
      <el-scrollbar class="databox">{{ address }}</el-scrollbar>
    </div>
    <el-divider></el-divider>
    <div>
      <h4>话题</h4>
      <div v-for="topic in topics" :key="topic">
        <el-scrollbar class="databox">{{ topic }}</el-scrollbar>
      </div>
    </div>
    <el-divider></el-divider>
    <div>
      <h4>数据</h4>
      <el-scrollbar class="databox">{{ data }}</el-scrollbar>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: ['address', 'topics', 'data'],
});
</script>

<style>
.databox {
  padding: 15px;
  margin: 5px;
  background: white;
  border-radius: 10px;
}
</style>
