<template>
  <div>
    <div>
      <el-page-header
        @back="goBack"
        title="区块列表"
        :content="headerTitle"
      ></el-page-header>
      <el-divider></el-divider>
      <div v-loading="!isLoaded">
        <div>
          <Overview
            v-if="isLoaded"
            :blockNumber="this.block.number"
            :blockHash="this.block.hash"
            :parentHash="this.block.parentHash"
            :difficulty="this.block.difficulty"
            totalDifficulty="getFromServer"
            :sealer="this.block.sealer"
            :txns="this.block.transactions"
            :timestamp="this.block.timestamp"
            :nonce="this.block.nonce"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Chain, JsonRpcProvider } from '@/assets/js/crypu.esm.min';

import Overview from './detail/overview.vue';

export default Vue.extend({
  data() {
    return {
      block: {},
      isLoaded: false,
      headerTitle: `区块 # ${this.$route.params.number}`,
      provider: new JsonRpcProvider(Chain.ETHERS, this.$store.state.node, {
        name: this.$store.state.network,
        chainId: this.$store.state.chainId,
      }),
    };
  },
  mounted() {
    this.$nextTick(async () => {
      this.block = await this.provider.getBlock(
        parseInt(this.$route.params.number, 10),
      );
      this.isLoaded = true;
    });
  },
  methods: {
    goBack() {
      this.$router.push('/block');
    },
  },
  components: {
    Overview,
  },
});
</script>

<style>
.item {
  margin: 4px;
}
</style>
