<template>
  <div>
    <el-page-header @back="goBack" content="交易详情"></el-page-header>
    <el-divider></el-divider>
    <div
      v-loading="receipt === undefined"
      element-loading-background="rgba(245, 247, 250, 0.8)"
    >
      <div v-if="receipt === null">
        <el-alert title="交易不存在" type="error" show-icon :closable="false">
        </el-alert>
      </div>
      <div v-else>
        <el-tabs
          v-model="activeTab"
          v-loading="datetime === undefined"
          element-loading-background="rgba(245, 247, 250, 0.8)"
        >
          <el-tab-pane label="概览" name="overview">
            <Overview
              v-if="receipt && datetime !== undefined"
              :blockNumber="this.receipt.blockNumber"
              :txhash="this.receipt.transactionHash"
              :from="this.receipt.from"
              :to="this.receipt.to"
              :datetime="this.datetime"
              :nonce="this.receipt.transactionIndex"
            />
          </el-tab-pane>
          <el-tab-pane label="日志" name="logs" v-if="hasLog">
            <Logs
              v-if="receipt && datetime !== undefined"
              :address="this.receipt.logs[0].address"
              :topics="this.receipt.logs[0].topics"
              :data="this.receipt.logs[0].data"
            />
          </el-tab-pane>
          <el-tab-pane label="合约关系" name="relation">
            <Graph
              v-if="receipt && datetime !== undefined"
              :address="this.receipt.to"
            />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import * as dayjs from 'dayjs';
import { Chain, JsonRpcProvider } from '@/assets/js/crypu.esm.min';

import Graph from '@/components/contract/graph.vue';
import Overview from './detail/overview.vue';
import Logs from './detail/logs.vue';

export default Vue.extend({
  data() {
    return {
      receipt: undefined,
      provider: new JsonRpcProvider(Chain.ETHERS, this.$store.state.node, {
        name: this.$store.state.network,
        chainId: this.$store.state.chainId,
      }),
      activeTab: 'overview',
      datetime: undefined,
      hasLog: false,
    };
  },
  mounted() {
    this.$nextTick(async () => {
      this.receipt = await this.provider.getTransactionReceipt(
        this.$route.params.hash,
      );
      const block = await this.provider.getBlock(this.receipt.blockNumber);
      this.datetime = dayjs(new Date(block.timestamp * 1000)).format(
        'YYYY/MM/DD HH:MM:ss',
      );
      if (this.receipt && this.receipt.logs.length > 0) {
        this.hasLog = true;
      }
    });
  },
  methods: {
    goBack() {
      this.$router.push('/transaction');
    },
  },
  components: {
    Overview,
    Logs,
    Graph,
  },
});
</script>

<style>
</style>
