<template>
  <div >
    <div v-if="validateHash" class="transactionDetail">
      <Detail />
    </div>
    <div v-else><Page404 msg="请输入正确的交易哈希值" /></div>
  </div>
</template>

<script>
import Vue from 'vue';

import Page404 from '@/views/404.vue';
import Detail from '@/components/transaction/detail.vue';

export default Vue.extend({
  computed: {
    validateHash() {
      return /^0x([A-Fa-f0-9]{64})$/.test(this.$route.params.hash);
    },
  },
  components: {
    Detail,
    Page404,
  },
});
</script>

<style>
.transactionDetail {
  margin-left: 30px;
  margin-right: 30px;
}
</style>
