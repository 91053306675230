<template>
  <div class="insight">
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card
          :body-style="{ padding: '20px' }"
          shadow="hover"
        >
          <div class="item text">
            <span>节点数量</span>
            <span class="right">
              <b>{{ this.nodeAmount }}</b>
            </span>
          </div>
          <el-divider></el-divider>
          <div class="item text">
            <span>交易总量</span>
            <span class="right">
              <b>{{ this.totalTxns }}</b>
            </span>
          </div>
          <el-divider></el-divider>
          <div class="item text">
            <span>区块高度</span>
            <span class="right">
              <b>{{ this.blockHeight }}</b>
            </span>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card
          :body-style="{ padding: '20px' }"
          shadow="hover"
        >
          <div class="item text">
            <span>今日交易</span>
            <span class="right">
              <b>{{ this.todayTxns }}</b>
            </span>
          </div>
          <el-divider></el-divider>
          <div class="item text">
            <span>今日出块</span>
            <span class="right">
              <b>{{ this.todayBlocks }}</b>
            </span>
          </div>
          <el-divider></el-divider>
          <div class="item text">
            <span>合约组数量</span>
            <span class="right">
              <b>{{ this.contractGroup }}</b>
            </span>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="hover">
          <Chart :data="chartData" class="chart" />
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Vue from 'vue';
import Chart from './echart.vue';

export default Vue.extend({
  name: 'Insight',
  props: {
    nodeAmount: Number,
    totalTxns: Number,
    blockHeight: Number,
    todayTxns: Number,
    todayBlocks: Number,
    contractGroup: Number,
  },
  data() {
    return {
      chartData: [23, 56, 34, 52, 67, 43, 123], // 七天交易数量
    };
  },
  components: {
    Chart,
  },
});
</script>

<style scoped>
.text {
  font-size: 14px;
}
.item {
  padding: 2px 0;
}
.box-card {
  width: 480px;
}
.right {
  float: right;
}
.chart {
  height: 178px;
  width: 100%;
}
</style>
