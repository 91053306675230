<template>
  <div>
    <el-row>
      <el-col :span="12">
        <div class="title">合约入口地址</div>
        <div class="value">{{ this.entrance }}</div>
      </el-col>
      <el-col :span="12">
        <div class="title">合约类型</div>
        <div class="value">{{ this.type }}</div>
      </el-col>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-col :span="12">
        <div class="title">交易数量</div>
        <div class="value">{{ this.txCount }}</div>
      </el-col>
      <el-col :span="12">
        <div class="title">合约数量</div>
        <div class="value">{{ this.ctCount }}</div>
      </el-col>
    </el-row>
  </div>
</template>

<style>
.title {
  color: grey;
  font-size: 14px;
  margin-left: 10px;
  display: inline-block;
}

.value {
  font-weight: bolder;
  margin-left: 10px;
  display: inline-block;
}

.group-margin {
  margin-left: 20px;
}
</style>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: ['name', 'entrance', 'type', 'txCount', 'ctCount'],
});
</script>
