<template>
  <div>
    <div v-if="layout === 'full'">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :current-page.sync="currentPage"
        :page-size="20"
        layout="total, prev, pager, next, jumper"
        :total="this.total"
      >
      </el-pagination>
      <el-divider></el-divider>
      <el-table
        :data="tableData"
        @row-click="handleRowClick"
        stripe
        style="width: 100%"
        empty-text="链上数据为空"
      >
        <el-table-column
          prop="txhash"
          label="交易哈希"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="from"
          label="发起地址"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="to"
          label="目的地址"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="erc165" label="ERC165"></el-table-column>
        <el-table-column prop="time" label="时间"></el-table-column>
      </el-table>
    </div>
    <div v-if="layout === 'compact'">
      <div v-for="data in tableData.slice(0, 7)" :key="data.txhash">
        <el-col>
          <el-row align="middle" justify="center" type="flex" @click.native="handleRowClick(data)">
            <el-col :span="8">
              <el-row class="address"
                >{{ data.txhash.substring(0, 12) }}...</el-row
              >
              <el-row class="time">{{ getTimelapse(data.time) }}</el-row>
            </el-col>
            <el-col :span="12">
              <el-row>
                <span class="label">来自 </span>
                <span class="link">{{data.from.substring(0, 12)}}...</span>
              </el-row>
              <el-row>
                <span class="label">发往 </span>
                <span class="link">{{data.to.substring(0, 12)}}...</span>
              </el-row>
            </el-col>
            <el-col :span="4" class="vertical-align">
              <el-tag>DID</el-tag>
            </el-col>
          </el-row>
          <el-divider></el-divider>
        </el-col>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/zh-cn';

export default Vue.extend({
  props: ['filter', 'layout'],
  data() {
    return {
      currentPage: 1,
      tableData: [],
      total: 1,
    };
  },
  methods: {
    handleCurrentChange(page) {
      this.currentPage = page;
      // load tx list with filter here
    },
    handleRowClick(row) {
      this.$router.push({ path: `/transaction/${row.txhash}` });
    },
    fetchTableData() {
      // fetch table data
      this.tableData = [
        {
          txhash:
            '0xda8d5bd51fdb7c1e046d2542e8764e1476d12f65c2cc3d39e08b8a835e69bd16',
          from: '0xE71eE3A87C2895a27459661495e8Ed5d93033350',
          to: '0x0D28588d9ace80CfEc2C9B06Fd26735a937f6dC7',
          erc165: '数字身份合约',
          time: Date.now() / 1000 - 30,
        },
        {
          txhash:
            '0xf44b47cd53c5ea1b3e47bfcd02bede296b693cc7e16c4fd664c3cbb7537be2f5',
          from: '0x1f403c619f4eec77a6bfe269a08a02232f21711b',
          to: '0x27fd7bd80732e28b7d04220e81ee991a02b90a71',
          erc165: '数字身份合约',
          time: Date.now() / 1000 - 30,
        },
        {
          txhash:
            '0xe4a984a88094ece49b11474990f4d444aa0c3af317966445b3632fd1b0263b0d',
          from: '0x1f403c619f4eec77a6bfe269a08a02232f21711b',
          to: '0x27fd7bd80732e28b7d04220e81ee991a02b90a71',
          erc165: '数字身份合约',
          time: Date.now() / 1000 - 30,
        },
        {
          txhash:
            '0xa6d5e7bd21b971631ccad200695416316f2a72e7d4c24e1b8d6afcdd161a47f5',
          from: '0x1f403c619f4eec77a6bfe269a08a02232f21711b',
          to: '0x27fd7bd80732e28b7d04220e81ee991a02b90a71',
          erc165: '数字身份合约',
          time: Date.now() / 1000 - 90,
        },
        {
          txhash:
            '0xae4384c6378c1c68b511c499f67e68d136980a566487cf52dbd42b8af1cf0bfa',
          from: '0xca946af3462455bc8e6e2d16169a7659d0a8b5d6',
          to: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
          erc165: '数字身份合约',
          time: Date.now() / 1000 - 90,
        },
      ];
      this.total = 1;
    },
    getTimelapse(time) {
      return dayjs.unix(time).fromNow();
    },
  },
  mounted() {
    this.fetchTableData(1);
    dayjs.extend(relativeTime);
    dayjs.locale('zh-cn');
  },
});
</script>

<style>
.address {
  font-size: 15px;
  font-weight: bold;
  color: cornflowerblue;
}
.time {
  font-size: 15px;
  color: grey;
}
.link {
  font-size: 15px;
  color: cornflowerblue;
}
.label {
  font-size: 15px;
  color: lightslategrey;
}
.vertical-align {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
