<template>
  <div>
    <div>
      <h4>一般信息</h4>
      <el-table :data="tableData" style="width: 100%" :show-header="false">
        <el-table-column fixed prop="title" label="标题" width="150">
        </el-table-column>
        <el-table-column prop="value" label="数据" width="auto">
        </el-table-column>
      </el-table>
    </div>
    <div>
      <span>
        <h4 style="display: inline-block">合约组信息</h4>
        <el-tooltip
          class="item"
          effect="dark"
          content="解码获得更多信息"
          placement="right"
        >
          <el-button
            style="margin-left: 20px"
            size="small"
            @click="contractDecode"
            >合约解码</el-button
          >
        </el-tooltip>
      </span>
      <div v-if="decodeVisible">
        这里是解码出来的信息
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Chain, JsonRpcProvider } from '@/assets/js/crypu.esm.min';

export default Vue.extend({
  props: ['txhash', 'blockNumber', 'from', 'to', 'nonce', 'datetime'],
  data() {
    return {
      tableData: [
        {
          title: '交易哈希',
          value: this.txhash,
        },
        {
          title: '区块号',
          value: this.blockNumber,
        },
        {
          title: '日期时间',
          value: this.datetime,
        },
        {
          title: '发送地址',
          value: this.from,
        },
        {
          title: '接收地址',
          value: this.to,
        },
        {
          title: '随机数',
          value: this.nonce,
        },
      ],
      provider: new JsonRpcProvider(Chain.ETHERS, this.$store.state.node, {
        name: this.$store.state.network,
        chainId: this.$store.state.chainId,
      }),
      decodeVisible: false,
    };
  },
  methods: {
    contractDecode() {
      this.decodeVisible = true;
    },
  },
});
</script>

<style>
.item {
  margin: 4px;
}
</style>
