<template>
  <div id="app" class="navbar">
    <el-row
      align="middle"
      justify="center"
      style="background-color: white"
      type="flex"
    >
      <el-col :span="4" style="display: flex; justify-content: center">
        <router-link to="/">
          <img height="44px" src="@/assets/img/brand.png" />
        </router-link>
      </el-col>
      <el-col :span="12">
        <el-menu
          :default-active="activePathFn"
          :router="true"
          :unique-opened="true"
          mode="horizontal"
        >
          <el-menu-item index="/transaction">交易</el-menu-item>
          <el-menu-item index="/block">区块</el-menu-item>
          <el-menu-item index="/contract">合约</el-menu-item>
          <el-menu-item index="/help" disabled>帮助</el-menu-item>
          <el-menu-item index="/about" disabled>关于</el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="8">
        <div class="search" style="align-items: center">
          <el-input v-model="input" placeholder="请输入交易哈希或合约地址">
            <el-button slot="append" align icon="el-icon-search"></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      input: '',
    };
  },
  computed: {
    activePathFn() {
      return this.$route.path;
    },
  },
  methods: {},
};
</script>

<style>
.title {
  display: flex;
  justify-content: center; /*主轴上居中*/
  align-content: middle; /*侧轴上居中*/
}

.navbar {
  border-bottom: 1px;
  border-bottom-style: solid;
  border-bottom-color: #dcdfe6;
  border-bottom-width: 1px;
  height: 61px;
}

.search {
  justify-content: center;
  align-content: middle;
  padding-right: 10px;
}

.content {
  padding: 20px;
}
</style>
