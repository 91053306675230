<template>
  <div id="transaction" class="transaction">
    <div>
      <h2>交易列表</h2>
      <span>仅显示最近1000笔交易</span>
      <el-divider></el-divider>
    </div>
    <div>
      <el-card shadow="never">
        <TxnList layout="full" />
      </el-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import TxnList from '@/components/transaction/list.vue';

export default Vue.extend({
  name: 'Transaction',
  components: {
    TxnList,
  },
});
</script>

<style>
.transaction {
  margin-left: 30px;
  margin-right: 30px;
}
</style>
