<template>
  <div>
    <el-card :body-style="{ padding: '20px' }">
      <div slot="header" class="clearfix">
        <span>
          <el-page-header
            @back="goBack"
            title="区块列表"
            :content="this.name"
          ></el-page-header>
        </span>
      </div>
      <Header
        :entrance="this.entrance"
        :type="this.type"
        :txCount="this.txCount"
        :ctCount="this.ctCount"
      />
      <el-divider></el-divider>
  <el-tabs tab-position="left" style="height: 70vh;">
    <el-tab-pane label="合约关系" style="height:100%;width:100%;">
      <EChart :address="this.$route.params.address"/>
    </el-tab-pane>
    <el-tab-pane label="最近交易">
      <List layout="full"/>
    </el-tab-pane>
    <el-tab-pane label="读取合约" disabled>读取合约</el-tab-pane>
    <el-tab-pane label="写入合约" disabled>写入合约</el-tab-pane>
  </el-tabs>
    </el-card>
  </div>
</template>

<script>
import Vue from 'vue';

import List from '@/components/transaction/list.vue';
import Header from './header.vue';
import EChart from './graph.vue';

export default Vue.extend({
  data() {
    return {
      name: '',
      entrance: '',
      type: '',
      txCount: '',
      ctCount: '',
    };
  },
  methods: {
    goBack() {
      this.$router.push('/contract');
    },
  },
  components: {
    Header,
    EChart,
    List,
  },
  mounted() {
    // Get contract info from server
    this.name = 'SMH-DID';
    this.entrance = '0x0D28588d9ace80CfEc2C9B06Fd26735a937f6dC7';
    this.type = '数字身份合约组';
    this.txCount = 2333;
    this.ctCount = 2;
  },
});
</script>
