<template>
  <div id="contract" class="contract">
    <div>
      <h2>合约列表</h2>
      <span>仅显示最近200个合约</span>
      <el-divider></el-divider>
    </div>
    <div>
      <el-card shadow="never">
        <el-tabs v-model="activeTab">
          <el-tab-pane label="数字身份" name="did"
            ><CntList category="did" :total="200"
          /></el-tab-pane>
          <el-tab-pane label="数据存储" name="bucket"
            ><CntList category="buckert" :total="200"
          /></el-tab-pane>
          <el-tab-pane label="FT" name="ft"
            ><CntList category="ft" :total="200"
          /></el-tab-pane>
          <el-tab-pane label="NFT" name="nft"
            ><CntList category="nft" :total="200"
          /></el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import CntList from '@/components/contract/list.vue';

export default Vue.extend({
  name: 'Contract',
  components: {
    CntList,
  },
  data() {
    return {
      activeTab: 'did',
    };
  },
});
</script>

<style>
.contract {
  margin-left: 30px;
  margin-right: 30px;
}
</style>
