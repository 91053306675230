<template>
  <div
    style="padding-top:100px; padding-left: 50px"
  >
    <img src="https://img.icons8.com/flat_round/64/000000/error--v2.png" />
    <h1>出错了... 404</h1>
    <p>{{ this.msg }}</p>
    <router-link to="/" tag="el-button">回到首页</router-link>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: ['msg'],
});
</script>
